
import AbpBase from "../../lib/abpbase";
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";

@Component
export default class PeoplePage extends AbpBase {
  name = "prople-page";
  @Prop({ type: String, default: "" }) title: string;
  @Prop({ type: String, default: "" }) createPermission: string;

  find() {
    this.$emit("find");
  }

  create() {
    this.$emit("create");
  }
}
