
import PageRequest from '@/store/entities/page-request';
import { Component, Vue, Inject, Prop } from 'vue-property-decorator';
import appconst from '@/lib/appconst'
import AbpBase from '@/lib/abpbase'

@Component
export default class PagedScreen extends AbpBase {

    createModalShow : boolean = false;
    editModalShow : boolean = false;
    findModalShow : boolean = false;

    context: string
    paginar : string
    pagination: any = {};

    pagerequest: any = {};

    create() {
        this.createModalShow = true;
    }
    find(){
        this.findModalShow = true
    }
    edit() {
        this.editModalShow = true
    }
    hide() {
        this.findModalShow = false
    }

    async mounted() {
        this.getpage()
    }

    async getpage() {

        this.pagerequest.maxResultCount = this.pageSize
        this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize
        
        await this.$store.dispatch({
            type: this.context + '/getAll',
            data: this.pagerequest
        })
        
        const pagination = { ...this.pagination }
        pagination.total = this.$store.state[this.context].totalCount;

        pagination.pageSize = this.$store.state[this.context].pageSize;

        pagination.current = this.$store.state[this.context].currentPage;
        pagination.context =this.context
        this.pagination = pagination
        
        
    }

    handleTableChange = async (pagination, filters, sorter) => {
        
        
        
        this.$store.commit( pagination.context + '/setCurrentPage', pagination.current)
        this.getpage()

        await this.getpage()
    }

    async getSearch(model) {
        model.forEach((element) => {
            if (element.value) {
                this.pagerequest[element.fieldBind] = element.value
            } else {
                delete this.pagerequest[element.fieldBind]
            }
        })

        await this.getpage()
        this.findModalShow = false
    }

    get list() {
        return this.$store.state[this.context].list
    }
    get loading() {
        return this.$store.state[this.context].loading
    }
    get pageSize() {
        return this.$store.state[this.context].pageSize
    }
    get totalCount() {
        return this.$store.state[this.context].totalCount
    }
    get currentPage() {
        return this.$store.state[this.context].currentPage
    }

    set currentPage(page) {
        this.$store.commit(this.context + '/setCurrentPage', page)
    }
}
